import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://98d47403ebef9d2f09c692b54dcf8567@sentry.printlabels.online/2",
  debug: false,
  environment: "staging",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});